import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import { client } from "./src/apollo/client"
import PopUpProvider from "./src/context/PopUpContext"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <PopUpProvider>{element}</PopUpProvider>
  </ApolloProvider>
)
