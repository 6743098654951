import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});
const cache = new InMemoryCache({ fragmentMatcher });


const aUrlPath = window.location.pathname.split("/")
const token = aUrlPath[aUrlPath.length - 1]
const bPreview = aUrlPath[1].toLowerCase() === "preview" || false

export const client = new ApolloClient({
  cache,
  fetch,
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
  headers: {
    Authorization: bPreview ? `Bearer ${token}` : "",
  },
})
